import Vue from 'vue';
import { ToastPlugin } from 'bootstrap-vue';

Vue.use(ToastPlugin);

let bvToast;
let createElement;

function messageToHTML(message, variant, icon) {
  return [createElement(
    'div', { class: ['row'] },
    [
      createElement('div', { class: ['col-1'] }, [icon ? createElement('i', { class: ['fal', icon, 'fa-lg', 'mr-2', `toast-text-${variant}`] }) : '']),
      createElement('div', { class: ['col-10', 'text-center'] }, [message]),
    ],
  )];
}

function iconToHTML(variant, icon) {
  return [createElement(
    'p', { class: ['text-center', 'mb-0', 'py-4'] },
    [
      createElement('i', { class: ['fal', icon, 'fa-5x', 'text-stroke-3', 'mr-1', `toast-text-${variant}`] }),
    ],
  )];
}

const toast = {
  config(t) {
    bvToast = t.$bvToast;
    createElement = t.$createElement;
  },

  toast(t, position, message, delay) {
    this.config(t);
    bvToast.toast(message, {
      variant: 'dark',
      toaster: position,
      noCloseButton: true, // hide title bar
      solid: true,
      autoHideDelay: delay === undefined ? 3000 : delay,
      noAutoHide: delay === 0 ? true : false, // eslint-disable-line
    });
  },

  info(t, message, delay) {
    this.config(t);
    this.toast(t, 'b-toaster-top-center', messageToHTML(message, 'info', 'fa-info-circle'), delay);
  },

  success(t, message, delay) {
    this.config(t);
    this.toast(t, 'b-toaster-top-center', messageToHTML(message, 'success', 'fa-check-circle'), delay);
  },

  warning(t, message, delay) {
    this.config(t);
    this.toast(t, 'b-toaster-top-center', messageToHTML(message, 'warning', 'fa-exclamation-circle'), delay);
  },

  danger(t, message, delay) {
    this.config(t);
    this.toast(t, 'b-toaster-top-center', messageToHTML(message, 'danger', 'fa-times-circle'), delay);
  },

  dark(t, message, icon, delay) {
    this.config(t);
    this.toast(t, 'b-toaster-top-center', messageToHTML(message, 'white', icon), delay);
  },

  icon(t, variant, icon, delay) {
    this.config(t);
    this.toast(t, 'b-toaster-top-full', iconToHTML(variant === 'dark' ? 'white' : variant, icon), !delay ? 1000 : delay);
  },
};

export default toast;
